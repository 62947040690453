import { GameState, FieldState } from "../State";
import { Ctx } from "boardgame.io";
import { FieldId } from "./FieldUtils";

export function isInCurrentHand(G: GameState, ctx: Ctx, idx: number) {
  return (idx >= 0 && idx < G.players[ctx.currentPlayer].hand.length);
}

export function isInField(G: GameState, ctx: Ctx, idx: number, field: FieldId) {
  return (idx >= 0 && idx < G.fields[field].cards.length);
}

import { GameState } from "../State";
import { Ctx } from "boardgame.io";
import { Suit, getSuit, isFaceCard, Card } from "./CardUtils";
import { Season, getRotationForSeason, FieldId } from "./FieldUtils";

function suitToSeason(s: Suit): Season {
  return (s as number) as Season;
}

export function onCardPlayed(G: GameState, ctx: Ctx, playedCard: Card, playedInField: FieldId) {
  if (isFaceCard(playedCard)) {
    if (Suit.Stars === getSuit(playedCard)) {
      G.starPlayedIn = playedInField;
      ctx.events?.setStage!("chooseSeason");
      return;
    }

    G.rotation = getRotationForSeason(playedInField, suitToSeason(getSuit(playedCard)));
  }

  ctx.events?.endTurn!();
}

export function chooseSeason(G: GameState, ctx: Ctx, chosenSeason: Season) {
  G.rotation = getRotationForSeason(G.starPlayedIn!, chosenSeason);
  ctx.events?.endTurn!();
}
import { Ctx } from "boardgame.io";
import { INVALID_MOVE } from 'boardgame.io/core';
import { GameState } from "../State";
import { FieldId, getCurrentSeason, Season } from "../utils/FieldUtils";
import { onCardPlayed } from "../utils/onCardPlayed";
import { isCardStack, getVal, CardLike, flattenAll, removeCards } from "../utils/CardUtils";
import { isInCurrentHand, isInField } from "../utils/sanityChecks";

export function canHarvest(G: GameState, field: FieldId) {
  return getCurrentSeason(field, G.rotation) !== Season.Winter;
}

function harvestImpl(G: GameState, ctx: Ctx, dryRun: boolean) {
  const cardIdx = G.chosenCard!;
  const fieldId = G.chosenField!;
  const cardIdxsToHarvest = G.chosenFieldCards || [];
  if (!canHarvest(G, fieldId) || !isInCurrentHand(G, ctx, cardIdx))
    return INVALID_MOVE;

  const field = G.fields[fieldId];
  const currentPlayer = G.players[ctx.currentPlayer];
  const handCard = currentPlayer.hand[cardIdx];

  let sum = 0;
  const harvestedCards: CardLike[] = [];

  for (const idx of cardIdxsToHarvest) {
    if (!isInField(G, ctx, idx, fieldId))
      return INVALID_MOVE;

    let fieldCard = field.cards[idx];
    harvestedCards.push(fieldCard);

    if (isCardStack(fieldCard)) {
      if (fieldCard.isMulti && cardIdxsToHarvest.length > 1)
        return INVALID_MOVE;

      sum += getVal(fieldCard);
    } else if (cardIdxsToHarvest.length > 1) {
      const val = getVal(fieldCard);
      sum += (val === 14) ? 1 : val;
    } else {
      sum += getVal(fieldCard);
    }
  }

  if (sum !== getVal(handCard))
    return INVALID_MOVE;

  if (dryRun)
    return;

  field.cards = removeCards(field.cards, ...harvestedCards);
  currentPlayer.hand = removeCards(currentPlayer.hand, handCard);
  currentPlayer.collectedCards.push(...flattenAll(harvestedCards), handCard);

  onCardPlayed(G, ctx, handCard, fieldId);
}

export function isValidHarvest(G: GameState, ctx: Ctx) {
  return harvestImpl(G, ctx, true) !== INVALID_MOVE;
}

export function harvest(G: GameState, ctx: Ctx) {
  return harvestImpl(G, ctx, false);
}
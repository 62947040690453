import { Ctx } from "boardgame.io";
import { INVALID_MOVE } from 'boardgame.io/core';
import { GameState } from "../State";
import { FieldId, getCurrentSeason, Season } from "../utils/FieldUtils";
import { onCardPlayed } from "../utils/onCardPlayed";
import { isInCurrentHand } from "../utils/sanityChecks";

export function canSow(G: GameState, field: FieldId) {
  return getCurrentSeason(field, G.rotation) !== Season.Autumn;
}

export function sow(G: GameState, ctx: Ctx) {
  const cardIdx = G.chosenCard!;
  const field = G.chosenField!;
  if (!canSow(G, field) || !isInCurrentHand(G, ctx, cardIdx))
    return INVALID_MOVE;

  const cardToSow = G.players[ctx.currentPlayer].hand.splice(cardIdx, 1)[0];
  G.fields[field].cards.push(cardToSow);
  onCardPlayed(G, ctx, cardToSow, field);
}
import React from 'react';

interface IllimatProps {
  rotation: number,
  numOkus: number,
}

export function Illimat({ rotation, numOkus }: IllimatProps) {
  const allOkus = ["⚙️", "🗡️", "🗝️", "💎"];
  let okus = [];
  while (okus.length < numOkus) {
    okus.push(<span className="okus" key={okus.length}>{allOkus.pop()}</span>)
  }
  const classNames = `illimat rotate-${rotation}`;
  return (
    <div className={classNames}>
      <div className="remaining-okus">{okus}</div>
    </div>
  );
}

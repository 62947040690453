import { Ctx } from 'boardgame.io';
import React, { useEffect } from 'react';
import { isValidHarvest } from '../api/moves/Harvest';
import { canSow } from '../api/moves/Sow';
import { findValidStockpile } from '../api/moves/Stockpile';
import { GameState } from '../api/State';
import { FieldId } from '../api/utils/FieldUtils';
import { FaceUpCardLike } from './Card';
import { Field } from './Field';
import { Illimat } from './Illimat';
import { PlayerHand } from './PlayerHand';

const prompts: any = {
  pickCard: "Pick a card from your hand.",
  pickPassive: "Pick \"passive\" card from your hand.",
  pickField: "Pick a field.",
  pickFieldCards: "Pick card(s) from field.",
  pickMove: "Pick a move: ",
}

interface GameBoardProps {
  G: GameState;
  ctx: Ctx;
  moves: any;
}

type FieldOnClick<T extends any[]> = null | ((...x: T) => void);
type FieldOnClickCreator<T extends any[]> = (f: FieldId) => FieldOnClick<T>;

function wrapForBannedField<T extends any[]>(G: GameState, cb: FieldOnClickCreator<T>): FieldOnClickCreator<T> {
  return (field) => {
    if (!canSow(G, field))
      return null;

    return cb(field);
  };
}


function getContext(G: GameState, ctx: Ctx, moves: any) {
  const curStage = ctx.activePlayers![ctx.currentPlayer];
  const sum = G.chosenFieldCards?.map((idx, c) => (
    <>
      {(c > 0) ? " + " : ""}
      <FaceUpCardLike data={G.fields[G.chosenField!].cards[idx]} />
    </>
  ));

  const onClick = (move: string, ...args: any[]) => {
    return () => {
      if (curStage === "pickFieldCards")
        moves[move](...args);
    };
  };

  let divs = []

  if (curStage === "pickFieldCards") {
    if (isValidHarvest(G, ctx)) {
      divs.push(
        <div className="context-options" onClick={onClick("harvest")}>
          <span>Click to HARVEST</span>
          {sum}
          =
          <FaceUpCardLike data={G.players[ctx.currentPlayer].hand[G.chosenCard!]} />
        </ div>
      );
    }
    let attempt = findValidStockpile(G, ctx);
    if (attempt) {
      const [passiveIdx, stack] = attempt;
      divs.push(
        <div className="context-options" onClick={onClick("stockpile", passiveIdx)}>
          <span>Click to STOCKPILE</span>
          <FaceUpCardLike data={G.players[ctx.currentPlayer].hand[G.chosenCard!]} />
          +
          {sum}
          =>
          <FaceUpCardLike data={stack} />
          =
          <FaceUpCardLike data={G.players[ctx.currentPlayer].hand[passiveIdx]} />
        </div>
      );
    }
  }

  return (divs === []) ? undefined : divs;
}

export function GameBoard({ G, ctx, moves }: GameBoardProps) {
  const curStage = ctx.activePlayers![ctx.currentPlayer];
  let onClickHand = (moves.pickCard) ? (idx: number) => moves.pickCard(idx) : null;

  let onClickField = (field: FieldId) => (curStage === "pickFieldCards") ? (() => { moves.pickField(field); moves.sow(); }) : null;
  let onClickFieldCard = (field: FieldId) => (curStage === "pickFieldCards") ? ((idx: number) => moves.pickFieldCard(field, idx)) : null;

  onClickField = wrapForBannedField(G, onClickField);

  useEffect(() => {
    const onKeyPress = (evt: KeyboardEvent) => {
      console.log(evt.key);
      if (evt.key === "Enter" && (curStage === "pickFieldCards")) {
        moves[G.chosenMove!]();
      }
    }
    window.addEventListener("keypress", onKeyPress);
    return () => window.removeEventListener("keypress", onKeyPress);
  }, [moves, G, curStage]);

  const ctxDiv = getContext(G, ctx, moves);
  const ctxCls = `context-options-wrap ${(ctxDiv) ? "" : "hide"}`;

  return (
    <div className="game">
      <div className="fields">
        <Field G={G} id="NW" cards={G.fields.NW.cards} onClick={onClickField("NW")} onCardClick={onClickFieldCard("NW")} />
        <Field G={G} id="NE" cards={G.fields.NE.cards} onClick={onClickField("NE")} onCardClick={onClickFieldCard("NE")} />
        <Field G={G} id="SE" cards={G.fields.SE.cards} onClick={onClickField("SE")} onCardClick={onClickFieldCard("SE")} />
        <Field G={G} id="SW" cards={G.fields.SW.cards} onClick={onClickField("SW")} onCardClick={onClickFieldCard("SW")} />
      </div>
      <Illimat numOkus={G.okusRemaining} rotation={G.rotation} />
      <div className={ctxCls}>
        {ctxDiv}
      </div>
      <div className="sidebar">
        <span className="prompt">{G.chosenMove ? `${G.chosenMove.toLocaleUpperCase()}: ` : ""}{prompts[curStage]}</span>
        <PlayerHand onClick={onClickHand} hand={G.players[ctx.currentPlayer].hand} collected={G.players[ctx.currentPlayer].collectedCards} chosen={G.chosenCard} />
      </div>
    </div >
  );
}

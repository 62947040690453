import React from 'react';
import { CardLike } from '../api/utils/CardUtils';
import { FieldId } from '../api/utils/FieldUtils';
import { FaceUpCardLike } from './Card';
import { GameState } from '../api/State';

interface FieldProps {
  id: FieldId;
  cards: CardLike[];
  onClick: null | ((id: FieldId) => void);
  onCardClick: null | ((idx: number) => void);
  G: GameState;
}

export function Field({ G, id, cards, onClick, onCardClick }: FieldProps) {
  const sowButton = (onClick) ? <div className="sowbutton" onClick={() => onClick(id)}>Click to SOW</div> : undefined;
  const isChosenField = G.chosenField === id;
  return (
    <div className={`field field-${id}`}>
      <div className="fieldcards">
        {cards.map((v, idx) => (
          <FaceUpCardLike
            key={idx}
            data={v}
            chosen={isChosenField && G.chosenFieldCards && G.chosenFieldCards.includes(idx)}
            onClick={(onCardClick) ? () => onCardClick(idx) : undefined}
          />
        ))}
      </div>
      <Luminary id={(!!G.fields[id].luminary) && (G.fields[id].luminary as any).name} />
      {sowButton}
    </div>
  );
}


interface LuminaryProps {
  id: string | null | false;
}

export function Luminary({ id }: LuminaryProps) {
  if (undefined === id)
    return <></>;

  if (!id)
    return (<div className="luminary luminary-facedown" />);

  return (
    <div className={`luminary luminary-${id}`} />
  );
}

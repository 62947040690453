import { Ctx } from 'boardgame.io';
import { FieldState, GameState, PlayerState } from './State';
import { Card, generateDeck } from './utils/CardUtils';

function setupPlayer(id: number, deck: Card[]): PlayerState {
  const hand = [deck.pop()!, deck.pop()!, deck.pop()!];
  if (id !== 0)
    hand.push(deck.pop()!)

  return {
    hand,
    collectedCards: [],
    collectedOkus: 0,
    collectedLuminaries: [],
  }
}

function setupField(deck: Card[]): FieldState {
  return {
    cards: [deck.pop()!, deck.pop()!, deck.pop()!],
    luminary: false
  }
}

export function setupGame(ctx: Ctx): GameState {
  const deck = ctx.random!.Shuffle(generateDeck(ctx.numPlayers));
  const players: { [id: string]: PlayerState } = {};
  for (let i = 0; i < ctx.numPlayers; i++) {
    players[String(i)] = setupPlayer(i, deck);
  }
  return {
    players,
    fields: {
      NW: setupField(deck),
      NE: setupField(deck),
      SE: setupField(deck),
      SW: setupField(deck),
    },
    okusRemaining: 4,
    cardsRemaining: deck.length,
    rotation: 0,
    secret: {
      deck,
      faceDownLuminaries: [
        { name: "A", description: "foo" },
        { name: "B", description: "foo" },
        { name: "C", description: "foo" },
        { name: "D", description: "foo" },
      ],
    }
  }
}

import React from 'react';
import { Card, CardLike, getSuitEmoji, isCardStack, getVal, getSuit } from '../api/utils/CardUtils';
import { FaceUpCardStack } from './CardStack';

interface FaceUpCardProps {
  card: Card;
  chosen?: boolean | null;
  onClick?: null | (() => void);
}

export function FaceUpCard({ chosen, card, onClick }: FaceUpCardProps) {
  const suit = getSuitEmoji(getSuit(card));
  const val = getVal(card);
  return (
    <div
      onClick={onClick || undefined}
      className={`card cardlike ${onClick ? "clickable" : ""} ${chosen ? "chosen" : ""} cardface-${val}-${suit}`}>
      {val}<br />{suit}
    </div>
  );
}

interface FaceUpCardLikeProps {
  data: CardLike;
  chosen?: boolean | null;
  onClick?: null | (() => void);
}

export function FaceUpCardLike({ chosen, data, onClick }: FaceUpCardLikeProps) {
  if (isCardStack(data))
    return <FaceUpCardStack chosen={chosen} onClick={onClick} stack={data} />;

  return <FaceUpCard chosen={chosen} onClick={onClick} card={data} />
}

import { Ctx } from "boardgame.io";
import { INVALID_MOVE } from 'boardgame.io/core';
import { GameState } from "../State";
import { CardLike, createStack, flattenAll, getVal, isCardStack, removeCards } from "../utils/CardUtils";
import { getCurrentPlayer } from "../utils/ContextUtils";
import { FieldId, getCurrentSeason, Season } from "../utils/FieldUtils";
import { onCardPlayed } from "../utils/onCardPlayed";
import { isInCurrentHand, isInField } from "../utils/sanityChecks";

export function canStockpile(G: GameState, field: FieldId) {
  return getCurrentSeason(field, G.rotation) !== Season.Spring;
}

function stockpileImpl(G: GameState, ctx: Ctx, passiveCardIdx: number, dryRun: boolean) {
  const activeCardIdx = G.chosenCard!;
  const fieldId = G.chosenField!;
  const cardIdxsToCombine = G.chosenFieldCards || [];
  if (!canStockpile(G, fieldId) || !isInCurrentHand(G, ctx, activeCardIdx))
    return INVALID_MOVE;

  if (!isInCurrentHand(G, ctx, passiveCardIdx) || activeCardIdx === passiveCardIdx)
    return INVALID_MOVE;

  if (cardIdxsToCombine.length < 1)
    return INVALID_MOVE;

  const field = G.fields[fieldId];
  const currentPlayer = G.players[ctx.currentPlayer];
  const activeCard = currentPlayer.hand[activeCardIdx];
  const passiveCard = currentPlayer.hand[passiveCardIdx];

  let sum = (getVal(activeCard) === 14) ? 1 : getVal(activeCard);
  const stockpiledCards: CardLike[] = [];
  const multiStockpiledCards: CardLike[] = [];

  for (const idx of cardIdxsToCombine) {
    if (!isInField(G, ctx, idx, fieldId))
      return INVALID_MOVE;

    let fieldCard = field.cards[idx];
    if (getVal(fieldCard) === getVal(passiveCard)) {
      if (isCardStack(fieldCard) && fieldCard.isMulti && cardIdxsToCombine.length > 1)
        return INVALID_MOVE;

      multiStockpiledCards.push(fieldCard);
    } else {
      if (isCardStack(fieldCard) && fieldCard.isMulti)
        return INVALID_MOVE;

      stockpiledCards.push(fieldCard);
      sum += (getVal(fieldCard) === 14) ? 1 : getVal(fieldCard);
    }
  }

  if (sum !== getVal(passiveCard) || (sum === 1 && getVal(passiveCard) === 14))
    return INVALID_MOVE;

  let stack: CardLike = activeCard;
  if (stockpiledCards.length > 0)
    stack = createStack([activeCard, ...flattenAll(stockpiledCards)], false);
  if (multiStockpiledCards.length > 0)
    stack = createStack([...multiStockpiledCards, stack], true);

  if (dryRun)
    return stack;

  field.cards = removeCards(field.cards, ...stockpiledCards, ...multiStockpiledCards);
  field.cards.push(stack);
  currentPlayer.hand = removeCards(currentPlayer.hand, activeCard);

  onCardPlayed(G, ctx, activeCard, fieldId);
}

export function isValidStockpile(G: GameState, ctx: Ctx, passiveCardIdx: number) {
  return stockpileImpl(G, ctx, passiveCardIdx, true) !== INVALID_MOVE;
}

export function findValidStockpile(G: GameState, ctx: Ctx): [number, CardLike] | undefined {
  for (let i = 0; i < getCurrentPlayer(G, ctx).hand.length; i++) {
    if (i === G.chosenCard)
      continue;

    let attempt = stockpileImpl(G, ctx, i, true);
    if (attempt !== INVALID_MOVE)
      return [i, attempt!]
  }
}

export function stockpile(G: GameState, ctx: Ctx, passiveIdx: number) {
  return stockpileImpl(G, ctx, passiveIdx, false);
}
import { Ctx, Game } from 'boardgame.io';
import { Client } from 'boardgame.io/react';
import { sow } from './api/moves/Sow';
import { harvest } from './api/moves/Harvest';
import { stockpile, findValidStockpile } from './api/moves/Stockpile';
import { setupGame } from './api/Setup';
import { GameState } from './api/State';
import { GameBoard } from './components/GameBoard';
import "./App.css";
import { FieldId } from './api/utils/FieldUtils';
import { removeCardIdxs } from './api/utils/CardUtils';

function pickCard(G: GameState, ctx: Ctx, card: number) {
  G.chosenCard = (card === G.chosenCard) ? null : card;
  G.chosenField = null;
  G.chosenFieldCards = [];
  if (G.chosenCard !== null)
    ctx.events?.setStage!("pickFieldCards");
  else
    ctx.events?.setStage!("pickCard");
}

function pickField(G: GameState, ctx: Ctx, field: FieldId) {
  G.chosenField = field;
}

function pickFieldCard(G: GameState, ctx: Ctx, field: FieldId, card: number) {
  if (!G.chosenFieldCards || G.chosenField !== field) {
    G.chosenField = field;
    G.chosenFieldCards = []
  }
  if (G.chosenFieldCards.includes(card))
    G.chosenFieldCards = removeCardIdxs(G.chosenFieldCards, card);
  else
    G.chosenFieldCards.push(card);

  if (G.chosenMove === "stockpile") {
    const attempt = findValidStockpile(G, ctx);
    if (attempt)
      G.chosenPassive = attempt[0];
    else
      G.chosenPassive = null;
  }
}

function restartTurn(G: GameState, ctx: Ctx) {
  G.chosenMove = null;
  G.chosenCard = null;
  G.chosenPassive = null;
  G.chosenField = null;
  G.chosenFieldCards = null;
  ctx.events!.setActivePlayers!({ currentPlayer: "pickCard" });
}

const IllimatGame: Game = {
  setup: (ctx: Ctx): GameState => setupGame(ctx),

  moves: {
    sow,
    harvest,
    stockpile,
  },
  turn: {
    onEnd: (G: GameState, ctx: Ctx) => {
      while (G.players[ctx.currentPlayer].hand.length < 4 && G.secret.deck.length > 0) {
        G.players[ctx.currentPlayer].hand.push(G.secret.deck.pop()!);
      }
    },
    onBegin: (G: GameState, ctx: Ctx) => {
      restartTurn(G, ctx);
    },

    stages: {
      pickCard: {
        moves: {
          pickCard,
          restartTurn,
        },
      },
      pickFieldCards: {
        moves: {
          pickCard,
          pickField,
          sow,
          pickFieldCard,
          harvest,
          stockpile,
          restartTurn,
        },
      }
    }
  }
};

const App = Client({
  game: IllimatGame,
  board: GameBoard,
});

export default App;
import React from 'react';
import { CardStack, flattenAll, isCardStack } from '../api/utils/CardUtils';
import { FaceUpCard } from './Card';

interface CardStackProps {
  stack: CardStack;
  chosen?: boolean | null;
  onClick?: null | (() => void);
}

export function FaceUpCardStack({ chosen, stack, onClick }: CardStackProps) {
  const commonClass = `cardlike cardstack ${onClick ? "clickable" : ""} ${chosen ? "chosen" : ""}`
  if (stack.isMulti) {
    let stackParts = [];
    let singleParts = [];
    for (const p of stack.parts) {
      if (isCardStack(p)) {
        stackParts.push(p);
      } else {
        singleParts.push(p);
      }
    }
    stackParts.sort((a, b) => b.parts.length - a.parts.length);

    return (
      <div onClick={onClick || undefined} className={`cardstack-multi ${commonClass}`}>
        {stackParts.map((data) => <FaceUpCardStack chosen={false} stack={data} />)}
        {singleParts.map((data) => <FaceUpCard chosen={false} card={data} />)}
      </div>
    );
  }

  const style = { "--stack-size": stack.parts.length } as any;
  return (
    <div style={style} onClick={onClick || undefined} className={`cardstack-reg ${commonClass}`}>
      {flattenAll(stack.parts).map((data) => <FaceUpCard chosen={false} card={data} />)}
    </div>
  );
}

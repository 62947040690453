import React from 'react';
import { Card } from '../api/utils/CardUtils';
import { FaceUpCard } from './Card';

interface PlayerHandProps {
  hand: Card[],
  collected: Card[],
  onClick: null | ((idx: number) => void),
  chosen?: number | null,
}

export function PlayerHand({ hand, collected, onClick, chosen }: PlayerHandProps) {

  return (
    <>
      <div className="player-hand">
        {hand.map((v, idx) => (<FaceUpCard key={idx} card={v} chosen={idx === chosen} onClick={(onClick) ? () => onClick(idx) : undefined} />))}
      </div>
      <div className="player-collected">
        {collected.map((v, idx) => (<FaceUpCard key={idx} card={v} chosen={false} />))}
      </div>
    </>
  );
}

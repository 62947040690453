export enum Season {
  Summer = 0,
  Autumn = 1,
  Winter = 2,
  Spring = 3,
};

export function getCurrentSeasons(rotation: number): Season[] {
  return [
    (Season.Summer + rotation) % 4,
    (Season.Autumn + rotation) % 4,
    (Season.Winter + rotation) % 4,
    (Season.Spring + rotation) % 4,
  ]
}

export function getCurrentSeason(field: FieldId, rotation: number): Season {
  const defaultSeason = fieldNames.indexOf(field) as Season;
  return (defaultSeason + rotation) % 4;
}

const fieldNames = ["NE", "NW", "SW", "SE"] as const;
export type FieldId = (typeof fieldNames)[number];

export function getRotationForSeason(field: FieldId, season: Season): number {
  let rotation = 0;
  while (getCurrentSeason(field, rotation) !== season)
    rotation++;

  return rotation;
}

export enum Suit {
  Summer,
  Autumn,
  Winter,
  Spring,
  Stars,
};

const suitEmoji: any = {
  "☀": Suit.Summer,
  "🍁": Suit.Autumn,
  "❄": Suit.Winter,
  "🍀": Suit.Spring,
  "⭐": Suit.Stars,
}

export type Card = string;
export interface CardStack {
  isCardStack: true;
  isMulti: boolean,
  parts: CardLike[],
}

export type CardLike = Card | CardStack;

export function isCardStack(c: CardLike): c is CardStack {
  return (c as any).isCardStack;
}

export function createStack(parts: CardLike[], multi: boolean): CardStack {
  return {
    isCardStack: true,
    isMulti: multi,
    parts,
  };
}

export function getSuit(c: Card): Suit {
  return suitEmoji[c.replace(/\d/g, "")];
}

export function getVal(c: CardLike): number {
  if (isCardStack(c)) {
    if (c.isMulti)
      return getVal(c.parts[0]);

    let sum = 0;
    for (const partVal of c.parts.map((v) => getVal(v))) {
      sum += (partVal === 14) ? 1 : partVal;
    }
    return sum;
  }

  return parseInt(c.replace(/(☀|🍁|❄|🍀|⭐)/, ""));
}

export function getFaceName(c: Card): string | undefined {
  switch (getVal(c)) {
    case 11:
      return "Knight";
    case 12:
      return "Queen";
    case 13:
      return "King";
    case 14:
      return "Fool";
  }
  return undefined;
}

export function getSuitEmoji(suit: Suit): string {
  switch (suit) {
    case Suit.Summer:
      return "☀";
    case Suit.Autumn:
      return "🍁";
    case Suit.Winter:
      return "❄";
    case Suit.Spring:
      return "🍀";
    case Suit.Stars:
      return "⭐";
  }
}

export function isFaceCard(c: Card): boolean {
  return getVal(c) > 10;
}

export function flatten(c: CardLike): Card[] {
  if (isCardStack(c))
    return c.parts.flatMap((v) => flatten(v));

  return [c];
}

export function flattenAll(c: CardLike[]): Card[] {
  return c.flatMap((v) => flatten(v));
}

export function removeCards<T extends CardLike>(haystack: T[], ...needles: CardLike[]): T[] {
  return haystack.filter((v) => !needles.includes(v));
}

export function removeCardIdxs(haystack: number[], ...needles: number[]): number[] {
  return haystack.filter((v) => !needles.includes(v));
}

export function generateDeck(numPlayers: number): Card[] {
  const allSuits = [Suit.Summer, Suit.Autumn, Suit.Winter, Suit.Spring]
  if (numPlayers > 3) {
    allSuits.push(Suit.Stars)
  }

  const cards = [];
  for (const suit of allSuits) {
    for (let i = 2; i < 15; i++) {
      const card = i + getSuitEmoji(suit);
      cards.push(card);
    }
  }

  return cards;
}

